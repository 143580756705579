<template>
    <div class="card">
        <div class="bg-blue-light px-1 rounded pb-2">
            <TitleButton
                class="mt-1"
                btnTitle="Go Back"
                title="Create Payment"
                @onClickAddNewButton="goToList"
            />

            <div class="row mt-1">
                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label for="colFormLabel" class="col-form-label">Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="payment.date"
                    >
                </div>

                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label for="colFormLabel" class="col-form-label text-end">Voucher</label>
                    <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input" readonly="readonly">
                    <div class="input-group input-group-merge invoice-edit-input-group">
                        <div class="input-group-text">
                            <span>{{ payment.voucher_prefix }}-</span>
                        </div>
                        <input type="text" class="form-control invoice-edit-input" placeholder="" v-model="payment.voucher_serial">
                    </div>
                </div>

                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                    <label for="colFormLabel" class="col-form-label">Paid from: </label>
                    <v-select
                        placeholder="Select Cash & Bank Account"
                        class="w-100"
                        v-model="credit.account_head_id"
                        :options="cashAndBack"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>

                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                <label for="colFormLabel" class="col-form-label">Party: </label>
                <v-select
                placeholder="Select Party"
                class="w-100"
                :options="contactProfiles"
                label="name"
                :reduce="name => name.id"
                v-model="payment.contact_profile_id"
                />
                </div>

                <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                <label for="colFormLabel" class="col-form-label text-end">Business</label>
                <v-select
                    placeholder="Select Business"
                    class="w-100"
                    :options="businesses"
                    label="name"
                    :reduce="name => name.id"
                    v-model="payment.business_id"
                />
                </div>
            </div>
        </div>
        <div class="px-2 mt-2">
            <AddFormElement
                class="mb-2"
                :accountHeads="accountHeads"
                v-for="(item, index) in payment.general_ledgers"
                :key="index"
                :index="index"
                :item="item"
                @onClose="onClose"
            />

            <div class="row me-1 mt-3">
                <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                    <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                </div>
                <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end" :value="totalDebit"></div>
                <!-- <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control" :value="totalCredit"></div> -->
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <div class="mb-2">
                        <label for="note" class="form-label fw-bold">Memo</label>
                        <textarea v-model="payment.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                    </div>
                </div>
            </div>

            <div class="mt-2 text-center">
                <hr class="mb-1">
                <button
                    :disabled="paymentLoading"
                    @click="savePayment(true)"
                    class="btn btn-primary me-1"
                >
                    <div v-if="paymentLoading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save
                </button>
                <button
                    :disabled="saveNewLoader"
                    @click="savePayment(false)"
                    class="btn btn-primary me-1"
                >
                    <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save & New
                </button>
                <button
                    @click="goToList" 
                    class="btn btn-outline-secondary cancel-btn"
                >
                    Cancel
                </button>
            </div>
      </div>
    </div>
</template>

<script>
import handlePayment from '@/services/modules/payment'
import { inject } from 'vue'
import {generateVoucherNumber, generateQuery} from "@/services/utils/voucherNumberGenerator";
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/company/payment/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'

export default {
    name: 'PaymentCreate',
    components: {
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        cashAndBack: [],
        accountHeads: [],
        contactProfiles : [],
        businesses : [],
        saveNewLoader: false,
        credit: {
            account_head_id: null
        },
        payment: {
            company_id:'',
            contact_profile_id: '',
            business_id: '',
            date: '',
            note: '',
            mop_references: 'mop',
            voucher_no : '',
            voucher_prefix : '',
            voucher_serial : '',
            // voucher_type: 'cash_voucher',
            general_ledgers: [
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                },
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: '',
                    tax_rate: null,
                    taxable_amount: null
                }
            ]
        }
    }),
    computed: {
        isLoading () {
            return this.paymentLoading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.payment.general_ledgers
        },
        totalDebit () {
            let total = 0;
            this.general.map(i => {
                if(i.debit) total += i.debit
            })
            return total
        },
        
    },
    
    watch: {
        'payment.voucher_prefix':function () {
            this.concatVoucher()
        },
        
        'payment.voucher_serial':function () {
            this.concatVoucher()
        }
    },
    
    methods: {
        concatVoucher(){
            this.payment.voucher_no = this.payment.voucher_prefix + '-' + this.payment.voucher_serial
        },
        
        async getCashAndBank() {
           // return;
            try {
                this.paymentLoading = true
                let res = await this.fetchCashAndBank(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.cashAndBack = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.paymentLoading = false
            }
        },

        async getAccountHeads() {
           // return;
            try {
                this.loading = true
                let res = await this.fetchAccountHeads(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getContactProfiles() {
          try {
            let companyId = this.$route.params.companyId
            let contactId = '';
            let q = '';
            let query = '?company_id=' + companyId + '&q=' + q + '&contact_id' + contactId;
            let res = await this.fetchContactProfiles(query)

            if(!res.status) {
            }
            if(res.status) {
              this.contactProfiles = res.data;
            }

          } catch (err) {
            if(!err.response) {
              this.showError('Something is wrong. Check your connectivity!!')
            }
            if(err.response) {
              this.showError(err.response.message)
            }
          } finally {
            this.loading = false
          }
        },

        async getBusinesses(){
          try {
            let companyId = this.$route.params.companyId
            let res = await this.fetchBusinessList('?company_id=' + companyId)

            if(!res.status) {
            }
            if(res.status) {
              this.businesses = res.data;
            }

          } catch (err) {
            if(!err.response) {
              this.showError('Something is wrong. Check your connectivity!!')
            }
            if(err.response) {
              this.showError(err.response.message)
            }
          } finally {
            this.loading = false
          }

        },

        goToList() {
            this.$router.push({name: 'payment-voucher-list', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            
            })
        },
        onClose(index) {
            this.payment.general_ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.payment.general_ledgers.push({
                account_head_id: null,
                debit: null,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        resetForm() {
            this.payment = {
                company_id:'',
                date: '',
                note: '',
                mop_references: 'mop',
                voucher_type: 'cash_voucher',
                general_ledgers: [
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    },
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: '',
                        tax_rate: null,
                        taxable_amount: null
                    }
                ]
            }
        },
        getFormData () {
            let formData = new FormData();
            if(!this.payment.contact_profile_id) this.payment.contact_profile_id = ''
            if(!this.payment.business_id) this.payment.business_id = ''
            Object.keys(this.payment).forEach(i => {
                if(i !== 'general_ledgers') {
                    formData.append(i, this.payment[i])
                }
            })
            let generalLedgerData = this.general.slice()
            generalLedgerData.push({
                account_head_id: this.credit.account_head_id,
                debit: null,
                credit: this.totalDebit,
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
            formData.append('general_ledgers', JSON.stringify(generalLedgerData))
            return formData;
        },
        async savePayment (redirect = false) {//createPayment
            this.payment.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            if(redirect) {
                this.paymentLoading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createPayment(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.resetForm()
                    if(redirect) this.goToList()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response?.data.message)
                }
            } finally {
                this.paymentLoading = false
                this.saveNewLoader = false
            }
        }
    },
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');

        const {
            fetchAccountHeads,
            createPayment,
            fetchCashAndBank,
            paymentLoading
        } = handlePayment()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchBusinessList,
        } = handleCBusinesses()

        return {
            fetchAccountHeads,
            createPayment,
            fetchCashAndBank,
            paymentLoading,
            showError,
            fetchContactProfiles,
            fetchBusinessList,
            showSuccess
        }
    },

    async mounted() {
        await this.getCashAndBank()
        await this.getAccountHeads()
        await this.getContactProfiles()
        await this.getBusinesses()
        this.payment.date = new Date().toISOString().split('T')[0]
        let voucher = await generateVoucherNumber(generateQuery(this.$route.params.companyId, 'payment_voucher', 'general_payment'));
        this.payment.voucher_prefix = voucher.prefix;
        this.payment.voucher_serial = voucher.serial;
    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>
